.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5px;
    width: 100%;
    height: 100%;
    padding: 20px 12px;
    border-radius: 12px;
    overflow: hidden;
}

.borderSvg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.borderRect {
    fill: none;
    stroke: var(--nihao-border-color); /* Цвет рамки */
    stroke-width: var(--nihao-border-width * 2); /* Толщина рамки */
    stroke-dasharray: 7; /* Длина и промежутки пунктиров */
    rx: 12px; /* Радиус скругления углов */
    ry: 12px; /* Радиус скругления углов */
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.header {
    text-align: center;
}

.headerImage {
    margin-bottom: 7px;
}

.content:last-child {
    margin-top: -8px;
}

.text {
}
